import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { BSC_CHAIN_ID, ENDTIME, EXPLORE_URL, INIT_AMOUNT, INIT_USER, contract, formatDateWithZone, trimAddress } from "../hooks/constant";
import { formatPrice, getContract } from "../hooks/contractHelper";
import { useCommonStats } from "../hooks/useBscCommon";
import { useAccountStats } from "../hooks/useBscAccount";
import { toast } from 'react-toastify';
import tokenAbi from '../json/token.json';
import stakeAbi from '../json/bscstake.json';
import { ethers } from "ethers";
import { getWeb3 } from "../hooks/connectors";
import Refsection from "./Refsection";
import bscIcon from '../Assets/img/bsc-icon.png'
import { useLocation } from "react-router-dom";
import { useAccount, useNetwork } from 'wagmi';
import { useEthersSigner } from "../hooks/useEthersProvider";
import { useWeb3Modal } from '@web3modal/react';
import Countdown, { zeroPad } from 'react-countdown';




function Home() {
    const { open } = useWeb3Modal()
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const [updater, setUpdater] = useState(new Date());
    const commonStats = useCommonStats(updater);
    const accStats = useAccountStats(updater);
    const [selectedAPY, setSelectedAPY] = useState(0);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [error, setError] = useState('');
    const timeElapsed = Date.now();
    const [refAddress, setRefAddress] = useState('');
    const search = useLocation().search;


    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return (<div class="row countdown-container">
                <div class="col countdown-box">
                    <div class="time" id="days">00</div>
                    <div class="label">Days</div>
                </div>
                <div class="col countdown-box">
                    <div class="time" id="hours">00</div>
                    <div class="label">Hours</div>
                </div>
                <div class="col countdown-box">
                    <div class="time" id="minutes">00</div>
                    <div class="label">Minutes</div>
                </div>
                <div class="col countdown-box">
                    <div class="time" id="seconds">00</div>
                    <div class="label">Seconds</div>
                </div>
            </div>);
        } else {
            // Render a countdown
            return (
                <div class="row countdown-container">
                    <div class="col countdown-box">
                        <div class="time" id="days">{zeroPad(days, 2)}</div>
                        <div class="label">Days</div>
                    </div>
                    <div class="col countdown-box">
                        <div class="time" id="hours">{zeroPad(hours, 2)}</div>
                        <div class="label">Hours</div>
                    </div>
                    <div class="col countdown-box">
                        <div class="time" id="minutes">{zeroPad(minutes, 2)}</div>
                        <div class="label">Minutes</div>
                    </div>
                    <div class="col countdown-box">
                        <div class="time" id="seconds">{zeroPad(seconds, 2)}</div>
                        <div class="label">Seconds</div>
                    </div>
                </div>
            )
        }
    };


    useEffect(() => {
        let refAddr = '';
        const queryChainId = new URLSearchParams(search).get('ref');
        if (queryChainId !== '') {
            refAddr = queryChainId;
        }
        setRefAddress(refAddr);
        // eslint-disable-next-line
    }, [])

    const handleChangeAPY = (e, index) => {
        e.preventDefault();
        setSelectedAPY(index);
    }

    const handleApprove = async () => {
        if (address) {
            if (chain && chain.id && parseInt(chain.id) === parseInt(BSC_CHAIN_ID) && commonStats.stakeTokenAddress !== '') {
                try {

                    setLoading(true);
                    let stakingContract = contract[BSC_CHAIN_ID].STAKE_ADDRESS;
                    let stakeContract = getContract(tokenAbi, commonStats.stakeTokenAddress, signer);
                    let amount = ethers.utils.parseEther('100000000000000000').toString();
                    let tx = await stakeContract.approve(stakingContract, amount, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(BSC_CHAIN_ID);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Smart Chain Network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const handleChangeAmount = (e) => {
        e.preventDefault();
        setAmount(e.target.value);
        if (isNaN(e.target.value)) {
            setError('Please enter valid amount.');
        }
        else {
            setError('');
        }
        return;
    }

    const handleMaxAmount = () => {
        setAmount(accStats.stakeTokenBalance);
        setError('');
    }

    const handleStake = async () => {
        setLoading(true);
        try {
            if (amount > 0 && !isNaN(amount)) {
                if (address) {
                    if (chain && chain.id && parseInt(chain.id) === parseInt(BSC_CHAIN_ID)) {
                        if (parseFloat(accStats.stakeTokenBalance) >= parseFloat(amount)) {
                            let tokenStakingAddress = contract[BSC_CHAIN_ID].STAKE_ADDRESS;
                            let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);
                            let stakeAmount = ethers.utils.parseUnits(amount.toString(), accStats.stakeTokenDecimals);
                            let refAddr = refAddress ? refAddress : '0x0000000000000000000000000000000000000000';
                            let tx = await stakeContract.stake(stakeAmount.toString(), selectedAPY, refAddr, { 'from': address });
                            const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                            toast.promise(
                                resolveAfter3Sec,
                                {
                                    pending: 'Waiting for confirmation 👌',
                                }
                            )

                            var interval = setInterval(async function () {
                                let web3 = getWeb3();
                                var response = await web3.eth.getTransactionReceipt(tx.hash);
                                if (response != null) {
                                    clearInterval(interval)
                                    if (response.status === true) {
                                        toast.success('success ! your last transaction is success 👍');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else if (response.status === false) {
                                        toast.error('error ! Your last transaction is failed.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else {
                                        toast.error('error ! something went wrong.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                }
                            }, 5000);

                        }
                        else {
                            toast.error('you don\'t have enough balance !');
                            setLoading(false);
                        }

                    }
                    else {
                        toast.error('Please select Smart Chain Network !');
                        setLoading(false);
                    }
                }
                else {
                    toast.error('Please Connect Wallet!');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Amount !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    const handleHarvest = async (index) => {
        try {

            if (address) {
                if (chain && chain.id && parseInt(chain.id) === parseInt(BSC_CHAIN_ID)) {
                    let tokenStakingAddress = contract[BSC_CHAIN_ID].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);

                    let tx = await stakeContract.harvest(index, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Smart Chain Network !');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    const handleRewardHarvest = async (index) => {
        try {

            if (address) {
                if (chain && chain.id && parseInt(chain.id) === parseInt(BSC_CHAIN_ID)) {
                    let tokenStakingAddress = contract[BSC_CHAIN_ID].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);

                    let tx = await stakeContract.harvestPendingReward({ 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Smart Chain Network !');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    return (
        <>
            <section className="banner-section">
                <div className="bg-layer"></div>
                <Container>
                    <Row>
                        <Col xs={12} md={6} lg={7}>
                            <h1>USDT Staking Dashboard</h1>
                            <Button className="theme-btn">Stake USDT</Button>
                            <h4>Powered by <img src={bscIcon} alt="" /> <span>BSC</span></h4>
                        </Col>
                        <Col xs={12} md={6} lg={5}>
                            <div className="stake-box">
                                <div className="staking-info">
                                    <div className="all-info">
                                        <div className="label-1">USDT STAKED</div>
                                        <div className="credit-stacked">{commonStats.totalStakedToken || INIT_AMOUNT ? formatPrice(parseFloat(INIT_AMOUNT) + parseFloat(commonStats.totalStakedToken)) : 0} <span>{commonStats.stakeTokenSymbol ? commonStats.stakeTokenSymbol : ' - '}</span></div>
                                    </div>
                                    <div className="all-info">
                                        <div className="label-1">USDT STAKERS</div>
                                        <div className="credit-stacked">{commonStats.totalStakers ? formatPrice(parseInt(INIT_USER) + parseInt(commonStats.totalStakers)) : 0}</div>
                                    </div>
                                    <div className="all-info">
                                        <div className="label-1">CHC Price</div>
                                        <div className="credit-stacked">${commonStats.chcPrice ? formatPrice(commonStats.chcPrice, 4) : 0}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-4" >
                        <a href="https://ultimate-bakedbeans.app" target="_blank" rel="noreferrer">
                            <h2 className="text-center text-white mb-0">Great News!</h2>
                            <p className="text-center text-white">New Dapps Launching in</p>
                            <Countdown
                                date={ENDTIME * 1000}
                                renderer={renderer}
                                key={Math.random()}
                            />
                            <h5 class="mt-1 text-white text-center">Invest BNB earn BNB</h5>
                            <p className="text-center">
                                <a href="https://ultimate-bakedbeans.app" target="_blank" rel="noreferrer" class="text-white text-center">
                                    https://ultimate-bakedbeans.app <svg viewBox="0 0 24 24" width="20" height="20" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                </a>
                            </p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-6 ">
                        {/* <section> */}

                        <h5 class="text-white">$CHC Chart</h5>
                        <iframe class="dexscreen-card" title="GLDN Chart" src="https://dexscreener.com/bsc/0x662F2476f4C3c84eDa8A88F30535FAfF7266263C?embed=1&amp;theme=dark&amp;info=0" style={{ "height": "500px", width: "100%" }}></iframe>

                        {/* </section> */}
                    </div>
                    <div className="col-12 col-md-6 ">

                        <section className="calculator-section">
                            {/* <div className="curve-left">
                            <img src={curveLeft} alt="curve-left" />
                        </div>
                        <div className="curve-right">
                            <img src={curveRight} alt="curve-right" />
                        </div> */}
                            {/* <Container> */}
                            {/* <Row className="justify-content-center">
                                    <Col xs={12} sm={12} md={8} lg={8}> */}
                            <div className="calc-box">
                                <h3>$USDT Calculator</h3>
                                <p>Stake USDT to earn CHC rewards up to 365% APY.</p>
                                <div className="add-liquidity">
                                    <div className="content">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="labels">
                                                <h6>USDT</h6>
                                            </div>
                                            <div className="labels d-flex">
                                                <input type="text" value={amount} onChange={(e) => handleChangeAmount(e)} placeholder="5000" />
                                                <Button className="input-button" onClick={() => handleMaxAmount()}>Max</Button>
                                            </div>

                                        </div>
                                        <span style={{ "fontSize": "14px" }} className='text-danger'>{error}</span>
                                    </div>
                                </div>
                                <div className="plus-sys">+</div>
                                <div className="add-liquidity-2">
                                    <p>Lock tokens for</p>
                                    <div className="text-center">
                                        {commonStats.bonous && commonStats.duration && commonStats.duration.map((rowdata, index) => {
                                            return (
                                                <button key={index} onClick={(e) => handleChangeAPY(e, index)} className={`btn-box ${selectedAPY === index ? 'active' : ''}`}>
                                                    {rowdata ? parseInt(rowdata / 86400) : ' - '} Days
                                                </button>
                                            )
                                        })}
                                    </div>
                                </div>
                                {/* <div className="plus-sys">
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                                        </div> */}
                                <h4>Upto {commonStats.bonous[selectedAPY] / 10}% Returns on {commonStats.duration[selectedAPY] / 86400} Days</h4>
                                <h4>locked until {formatDateWithZone(new Date(parseInt(timeElapsed) + parseInt(commonStats.duration[selectedAPY] * 1000)))}</h4>
                                {address ? (
                                    parseFloat(accStats.isApproved) >= parseFloat(amount) ? (
                                        <Button className="theme-btn w-100 mt-3" onClick={() => !loading ? handleStake() : null}>{loading ? 'Loading…' : 'Stake'}</Button>
                                        // <Button disabled={true} className="theme-btn w-100 mt-3" onClick={() => null}>{loading ? 'Loading…' : 'Stake'}</Button>
                                    ) : (
                                        <Button className="theme-btn w-100 mt-3" onClick={() => !loading ? handleApprove() : null}>{loading ? 'Loading…' : 'Approve'}</Button>
                                        // <Button disabled={true} className="theme-btn w-100 mt-3" onClick={() => null}>{loading ? 'Loading…' : 'Approve'}</Button>
                                    )
                                ) : (
                                    <Button type="button" onClick={() => open()} className="theme-btn w-100 mt-3">Connect Wallet</Button>
                                )}

                            </div>
                            {/* </Col>
                                </Row> */}

                            {/* </Container> */}
                        </section>
                    </div>
                </div>
            </div>

            <section>
                <Container>
                    <Row className="mt-5">
                        <Col sm={6}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="text-white">Current Week Top 4 Winner</h5>
                                <p>Prize : {commonStats.totalchc ? formatPrice(commonStats.totalchc) : 0} $CHC</p>
                            </div>
                            <Table bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>User Address</th>
                                        <th>Total Staked</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {commonStats.currentWeekTop.length > 0 ? (
                                        commonStats.currentWeekTop.map((rowdata, index) => {
                                            return (
                                                rowdata[0] !== '0x0000000000000000000000000000000000000000' &&
                                                <tr key={index}>
                                                    <th>{index + 1}</th>
                                                    <td>
                                                        <a href={`${EXPLORE_URL}/address/${rowdata[0]}`} target="_blank" rel="noreferrer">
                                                            {rowdata[0] ? trimAddress(rowdata[0]) : '-'}
                                                        </a>
                                                    </td>
                                                    <td>{rowdata[1] ? formatPrice(rowdata[1] / Math.pow(10, 18)) : ' - '} USDT</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={3}>
                                                <h5 className="m-0 text-white text-center">No Record Found.</h5>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </Col>
                        <Col sm={6}>
                            <h5 className="text-white">Last Week Top 4 Winner</h5>
                            <Table bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>User Address</th>
                                        <th>Reward</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {commonStats.lastWeekWinner.length > 0 ? (
                                        commonStats.lastWeekWinner.map((rowdata, index) => {
                                            return (
                                                rowdata[0] !== '0x0000000000000000000000000000000000000000' &&
                                                <tr key={index}>
                                                    <th>{index + 1}</th>
                                                    <td>
                                                        <a href={`${EXPLORE_URL}/address/${rowdata[0]}`} target="_blank" rel="noreferrer">
                                                            {rowdata[0] ? trimAddress(rowdata[0]) : '-'}
                                                        </a>
                                                    </td>
                                                    <td>{rowdata[2] ? formatPrice(rowdata[2] / Math.pow(10, 18)) : ' - '} CHC</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={3}>
                                                <h5 className="m-0 text-white text-center">No Record Found.</h5>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </Col>

                    </Row>
                </Container>
            </section>


            <section className="details-section">
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">CHC Earned</div>
                                <h4>{accStats.totalClaimedRewardTokenUser ? formatPrice(accStats.totalClaimedRewardTokenUser) : 0}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Your USDT Wallet Balance</div>
                                <h4>{accStats.stakeTokenBalance ? formatPrice(accStats.stakeTokenBalance) : 0}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Pending Reward</div>
                                <div className="justify-content-center align-items-center d-flex">
                                    <span className="mx-1">{accStats.pendingReward ? formatPrice(accStats.pendingReward) : 0}</span>
                                    <button onClick={handleRewardHarvest} className="theme-btn btn mx-2" type="button">Claim</button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Current Staked</div>
                                <h4>{accStats.currentStake ? formatPrice(accStats.currentStake) : 0}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Total Staked</div>
                                <h4>{accStats.totalStakedTokenUser ? formatPrice(accStats.totalStakedTokenUser) : 0}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Total UnStaked</div>
                                <h4>{accStats.totalUnstakedTokenUser ? formatPrice(accStats.totalUnstakedTokenUser) : 0}</h4>
                            </div>
                        </Col>
                    </Row>
                    <Refsection accStats={accStats} />
                    <Row className="mt-5">
                        <Col sm={12}>
                            <h3 className="text-white">Transctions History</h3>
                            <Table bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Staked Amount</th>
                                        <th>Stake Date</th>
                                        <th>Unstake Date</th>
                                        <th>Earn Reward</th>
                                        <th>Harvest</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accStats.totalStakeHistory.length > 0 ? (
                                        accStats.totalStakeHistory.map((rowdata, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{rowdata[2] ? formatPrice(rowdata[2] / Math.pow(10, accStats.stakeTokenDecimals)) : 0}</td>
                                                    <td>{rowdata[1] ? formatDateWithZone(rowdata[1] * 1000) : ' - '}</td>
                                                    <td>{rowdata[0] ? formatDateWithZone(rowdata[0] * 1000) : ' - '}</td>
                                                    <td>{accStats.rewardHistory[index] ? formatPrice(accStats.rewardHistory[index] / Math.pow(10, 18), 5) : 0}</td>
                                                    <td><Button onClick={() => handleHarvest(index)} disabled={rowdata[10]} className="theme-btn">Harvest</Button></td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>
                                                <h5 className="m-0 text-white">You Have No Stake Record Yet.</h5>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container >
            </section >
        </>
    )
}

export default Home
