import moment from "moment";

export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const contract = {
  97: {
    STAKE_ADDRESS: "0x59eF6efE076eD53C1eb375Bb6f22b271619dE121",
    MULTICALL_ADDRESS: "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18"
  },
  56: {
    STAKE_ADDRESS: "0x9a4cb73763edd75ffa8cb41d8e597dc8a9cb8ed6",
    MULTICALL_ADDRESS: "0xed386fe855c1eff2f843b910923dd8846e45c5a4"
  }
}

export const PROJECT_ID = "27b9720018bf7f8740936e6a6eb28604";
export const DEFAULT_RPC = "https://bsc-dataseed1.binance.org/"
// export const DEFAULT_RPC = "https://data-seed-prebsc-2-s2.bnbchain.org:8545"
export const BSC_CHAIN_ID = 56;
export const EXPLORE_URL = "https://bscscan.com"
export const INIT_USER = 1500
export const INIT_AMOUNT = 8000
export const ENDTIME = 1724076144

export const BSC_STAKE_APY =
  [
    {
      apy: "110",
      time: "30"
    },
    {
      apy: "125",
      time: "60 "
    },
    {
      apy: "200",
      time: "200"
    },
    {
      apy: "365",
      time: "365"
    }
  ]


export const formatDateWithZone = (unixTime, onlydate = false) => {
  try {
    if (onlydate) {
      let dateString = moment(unixTime).format("LL");
      return dateString;
    }
    else {
      let dateString = moment(unixTime).format('lll');
      return dateString;
    }
  }
  catch (err) {
    console.log(err.message);
  }

}



export const faqs = [
  {
      question: "How do I stake?",
      answer: "Get USDT on your decentralized wallet and connect it with Dapp. Enter the USDT you want to stake. Make sure to keep some BNB in your wallet for the gas fees."
  },
  {
      question: "What is the purpose of USDT Invest Zone?",
      answer: "The main purpose is to give people a place to earn in defi. USDTInvestZone will 100% pay 200% in 200 days guarantee. The 2nd purpose will be for token price appreciation."
  },
  {
      question: "What is the minimum stake?",
      answer: "The minimum amount to stake is $10."
  },
  {
      question: "What is the maximum stake?",
      answer: "There is no maximum."
  },
  {
      question: "What are the time durations of the stake?",
      answer: "Stakers can choose from 200 days, 250 days, 310 days which are the beneficial days. There is also 365 days, but it is used for CHC migration."
  },
  {
      question: "What is the difference between the 4 durations?",
      answer: "There will be a big advantage if you stake for a longer duration; the longer the duration, the higher the interest rate. 200% growth for 200 days stake, 260% growth for 250 days stake, 320% growth for 310 days stake, and 100% growth for 100 days stake."
  },
  {
      question: "How much can I earn in this staking?",
      answer: "There are many factors that will help you earn on this platform:\n1. There is a guaranteed 200% of CHC token you will receive in the staking contract.\n2. If the CHC token price appreciates, then it means it will double your earnings.\nSample Scenario: You stake $100 at a CHC price of $0.00002 for 200 days. After 200 days, if the CHC price is $0.00004, your income will be: $100 x 2 = $200 after 200 days. Since the price doubled, $200 x 2 = $400. So, you earn $300 from your $100 investment."
  },
  {
      question: "What will happen after the stake period?",
      answer: "If you have staked for 200 days, your staked USDT will be used to buy CHC tokens, thus increasing the token price. Your staking account will earn you 1% daily for 200 days. After 200 days, it will be the end of the contract."
  },
  {
      question: "Can I claim my initial staking amount of USDT?",
      answer: "After the staking contract, the USDT will be consumed. You will no longer get your USDT back as it is included in the daily returns."
  },
  {
      question: "What is harvest?",
      answer: "A harvest is claiming your rewards and transferring them to your wallet."
  },
  {
      question: "Is there a referral reward for this?",
      answer: "Yes, if you promote, you can get referral bonuses up to 5 levels."
  },
  {
      question: "How much do I earn in referral bonuses?",
      answer: "You will earn the following: Level 1 = 5%, Level 2 = 3%, Level 3 = 2%, Level 4 = 1%, and Level 5 = 1%."
  },
  {
      question: "What is the team's suggested strategy?",
      answer: "The best strategy is to stake your USDT for 310 days to get the maximum interest. By doing this, you will overcome deflation."
  }
];
