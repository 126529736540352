import { Accordion, Col, Container, Row } from "react-bootstrap";
import { faqs } from "../hooks/constant";


function Faqs() {
    return (
        <>
            <section className="faq-section">
                <div className="bg-circles"><img src={require('../Assets/img/circles.png')} alt="" /></div>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Accordion defaultActiveKey={0}>
                                {faqs.map((faq, index) => (
                                    <Accordion.Item eventKey={index.toString()} key={index}>
                                        <Accordion.Header>{faq.question}</Accordion.Header>
                                        <Accordion.Body>{faq.answer}</Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Faqs